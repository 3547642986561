* {
    box-sizing: border-box;
}

html {
    /* Prevent bounce effect on mobile devices */
    width: 100%;
    height: 100%;
    overflow: hidden;
    overscroll-behavior-y: none;
}

body {
    background-color: rgb(89, 89, 89);
    color: black;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 0.8125rem;
    margin: 0;
    padding: 0;
}

button {
    font-family: inherit;
    font-size: inherit;
    margin: 2px 0 2px 0;
}

select {
    font-family: inherit;
    font-size: inherit;
    margin: 2px 0 2px 0;
}

input[type="range"] {
    margin: 2px 0 2px 0;
}

input[type=file] {
    display: none;
}

.message-dialog-overlay {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message-dialog {
    z-index: 11;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 10px 30px rgba(0, 0, 0, 0.4)
}

.message-dialog-inner {
    overflow: hidden;
    min-width: 20em;
    max-width: 75vw;
    padding: 1em;
}

.message-dialog-inner button {
    margin-top: 1em;
}

.align-right {
    text-align: right;
}

.panel {
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    border-top: 1px solid #d7d7d7;
    border-left: 1px solid #c9c9c9;
    border-right: 1px solid #c9c9c9;
    border-bottom: 1px solid #bbbbbb;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.panel .handle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 2.0em;
    background-color: #ededed;
    border: none;
    border-top: 1px solid #fefefe;
    border-radius: 0;
    cursor: move;
    padding: 0 4px;
}

.panel .handle button {
    height: 1.5em;
    width: 1.5em;
    text-align: center;
    vertical-align: middle;
    font-family: inherit;
    font-size: 12px;
    padding: 0 2px;
}

.control-panel {
    position: absolute;
    top: 1em;
    left: 1em;
    min-width: 20em;
    z-index: 1;
    box-shadow: 2px 10px 30px rgba(0, 0, 0, 0.4)
}

.control-panel-inner {
    padding: 4px;
}

.label-input-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 1em;
    align-items: center;
    align-self: center;
}

.label-input-grid-item {
    text-align: right;
    width: 100%;
}

.label-input-grid-item select {
    width: 100%;
}

.label-input-grid-item input {
    width: 100%;
}

.button-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
}

.button-grid-item {
    width: 100%;
}

.button-grid-item button {
    width: 100%;
}

.sketch {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    left: 0;
    top: 0;
    z-index: 0;
}

